
import { useCheckout } from '@/composables/checkout/useCheckout'
import { computed, defineComponent, onMounted, reactive, watchEffect, ref } from 'vue'
import PurchaseSummary from '@/components/pages/checkout/PurchaseSummary.vue'
import { fbPixel } from '@/plugins/facebook/pixel'

export default defineComponent({
  components: { PurchaseSummary },

  setup () {
    const playerContainer = ref<HTMLIFrameElement | null>(null)

    const {
      isLoaded: isCheckoutDataLoaded,
      products,
      checkoutFormData,
      submitCheckoutStatus,
    } = useCheckout()
    
    const form = reactive({
      email: ''
    })

    const isBoleto = computed(() => {
      const paymentMethod = submitCheckoutStatus.value.data?.paymentMethod
      return paymentMethod === 'boleto'
    })

    watchEffect(() => {
      if (isCheckoutDataLoaded.value) {
        products.value.map((product: {id: string, name: string, price: number}) => {
          const payload = {
            content_type: 'product',
            content_name: product.name,
            content_ids: [product.id],
            value: product.price,
            currency: 'BRL'
          }
          fbPixel('track', 'Purchase', payload)
          fbPixel('trackSingleCustom','796704134043684', 'COMPROUMANY')
          fbPixel('trackSingleCustom','768749882041585', 'COMPROUMANY2')
        })
      }
    })

    onMounted(() => {
      fbPixel('track', 'PageView')
      
      // Tenta desmutar o vídeo após 2 segundos
      setTimeout(() => {
        if (playerContainer.value && playerContainer.value.contentWindow) {
          playerContainer.value.contentWindow.postMessage(
            '{"event":"command","func":"unMute","args":""}', 
            '*'
          )
        }
      }, 1000)
    })

    return {
      form,
      isCheckoutDataLoaded,
      products,
      checkoutFormData,
      isBoleto,
      playerContainer
    }
  }
})
