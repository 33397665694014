<template>
  <div>
    <header>
      <img src="@/assets/logo.svg" alt="manycontent" class="w-42"/>
      <div class="hero">
        <h1>
          Recebemos o seu pedido de assinatura.
        </h1>
        <h2 v-if="isBoleto">
          Estamos <b>processando o seu pedido</b>, enviaremos uma mensagem no seu email de cadastro <span v-if="checkoutFormData.email">{{checkoutFormData.email}}</span> com as informações para pagamento do boleto.
        </h2>
        <h2 v-else>
          Estamos <b>processando o seu pedido</b>, enviaremos uma mensagem no seu email de cadastro <span v-if="checkoutFormData.email">{{checkoutFormData.email}}</span> assim que sua assinatura estiver concluída.
        </h2>
        <p></p>
      </div>
    </header>

    <div class="px-4 w-full md:w-10/12 mx-auto py-8 flex flex-col md:flex-row justify-between items-start space-y-12 md:space-y-0 md:space-x-20">
      <div class="md:w-2/3">
        <div v-if="isBoleto" class="flex flex-col items-center gap-4">
          <div>
            Estamos <b>processando o seu pedido</b>, enviaremos uma mensagem no seu email de cadastro <span v-if="checkoutFormData.email">{{checkoutFormData.email}}</span> com as informações para pagamento do boleto.
          </div>
          <div>
            Assim que identificarmos o pagamento nós enviaremos para o mesmo email os dados de acesso.
          </div>
          <div class="w-full py-8"><hr></div>
        </div>

        <p class="font-bold text-lg mb-2 text-center lg:text-left">
          Próximo passo - Como acessar a Manycontent e receber seus primeiro conteúdos.
        </p>
        <p class="text-sm md:text-base text-center lg:text-left">
          Assista ao vídeo com o passo a passo sobre como acessar a manycontent e configurar o seu primeiro perfil.
        </p>

        <div class="video-section py-6 lg:w-10/12 mx-auto">
          <div class="player-wrapper">
            <iframe 
              ref="playerContainer"
              class="player-container"
              :src="`https://www.youtube.com/embed/ssJ2yLjNnfg?autoplay=1&mute=1&enablejsapi=1&controls=1&rel=0`"
              allow="autoplay; encrypted-media"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <p class="hidden md:block text-center lg:text-left">
          * Se precisar de ajuda sinta-se à vontade para entrar em contato com nossa <a href="http://suporte.manycontent.com/" target="_blank" rel="noopener noreferrer">Equipe de suporte</a>.
        </p>
      </div>
      <div class="md:w-1/3">
        <div class="flex flex-col justify-center space-y-8 lg:w-full">
          <div class="space-y-8 mb-8" v-if="isCheckoutDataLoaded">
            <p class="font-semibold">
              Resumo da compra
            </p>
            <div>
              <p class="flex justify-between">Solicitante: <span>{{checkoutFormData.name}}</span></p>
              <p class="flex justify-between">Email: <span>{{checkoutFormData.email}}</span></p>
              <p class="flex justify-between">telefone: <span>{{checkoutFormData.phone}}</span></p>
            </div>
            <purchase-summary />
            <hr>
          </div>
        </div>
        <div class="space-y-4 lg:w-full m-auto" v-if="!isBoleto">
          <p class="font-semibold text-base md:text-lg text-center lg:text-left">
            Você receberá <b>um email</b> confirmando a sua assinatura.
          </p>
          <p class="text-sm md:text-base text-center lg:text-left">
            Como começar a usar a Manycontent
          </p>
          <ul class="text-sm leading-relaxed list-inside list-decimal my-8">
            <li>Acesse a <a href="https://business.manycontent.com/auth/login">página de login</a> da Manycontent</li>
            <li>Digite seu email de cadastro</li>
            <li>Clique em "Enviar link de acesso"</li>
            <li>Verifique seu email e clique no link recebido</li>
            <li>Configure seu primeiro perfil seguindo nosso vídeo tutorial</li>
          </ul>
          <p class="text-sm text-center lg:text-left">
            💡 <strong>Dica:</strong> Adicione manycontent.com aos seus contatos para garantir que nossos emails cheguem sempre na sua caixa de entrada principal.
          </p>
        </div>
        <div class="lg:w-full mt-8">
          <a
            href="https://business.manycontent.com/auth/login"
            class="btn btn-primary whitespace-nowrap"
          >
            Acessar a Manycontent
          </a>
        </div>
        <small class="block md:hidden mt-8 text-center lg:text-left">
          * Se precisar de ajuda sinta-se à vontade para entrar em contato com nossa <a href="http://suporte.manycontent.com/" target="_blank" rel="noopener noreferrer">Equipe de suporte</a>.
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useCheckout } from '@/composables/checkout/useCheckout'
import { computed, defineComponent, onMounted, reactive, watchEffect, ref } from 'vue'
import PurchaseSummary from '@/components/pages/checkout/PurchaseSummary.vue'
import { fbPixel } from '@/plugins/facebook/pixel'

export default defineComponent({
  components: { PurchaseSummary },

  setup () {
    const playerContainer = ref<HTMLIFrameElement | null>(null)

    const {
      isLoaded: isCheckoutDataLoaded,
      products,
      checkoutFormData,
      submitCheckoutStatus,
    } = useCheckout()
    
    const form = reactive({
      email: ''
    })

    const isBoleto = computed(() => {
      const paymentMethod = submitCheckoutStatus.value.data?.paymentMethod
      return paymentMethod === 'boleto'
    })

    watchEffect(() => {
      if (isCheckoutDataLoaded.value) {
        products.value.map((product: {id: string, name: string, price: number}) => {
          const payload = {
            content_type: 'product',
            content_name: product.name,
            content_ids: [product.id],
            value: product.price,
            currency: 'BRL'
          }
          fbPixel('track', 'Purchase', payload)
          fbPixel('trackSingleCustom','796704134043684', 'COMPROUMANY')
          fbPixel('trackSingleCustom','768749882041585', 'COMPROUMANY2')
        })
      }
    })

    onMounted(() => {
      fbPixel('track', 'PageView')
      
      // Tenta desmutar o vídeo após 2 segundos
      setTimeout(() => {
        if (playerContainer.value && playerContainer.value.contentWindow) {
          playerContainer.value.contentWindow.postMessage(
            '{"event":"command","func":"unMute","args":""}', 
            '*'
          )
        }
      }, 1000)
    })

    return {
      form,
      isCheckoutDataLoaded,
      products,
      checkoutFormData,
      isBoleto,
      playerContainer
    }
  }
})
</script>

<style scoped>
header {
  padding: 1rem;
  background-image: -o-linear-gradient(top left, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left top, right bottom, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  --tw-gradient-to: #10b981;
}
header .hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
}
header .hero > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
header .hero * {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  text-align: center;
}
header .hero h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-family: "Open Sans",ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 700;
}
header .hero h2 {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
  font-family: "Open Sans",ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
.video-section {
  width: 100%;
}
.player-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.player-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
@media (min-width: 768px) {
  header .hero {
    width: 66.666667%;
  }
  header .hero h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  header .hero h2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}
</style>